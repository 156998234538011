.word-moveout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 12vw;
    font-family: 'Squada One', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: $white;
    line-height: 0.7;

    > span {
        display: block;
        position: relative;
        cursor: pointer;
    }

    span span {
        display: block;
        pointer-events: none;
    }

    svg {
        position: absolute;
        left: 50%;
        margin-left: -2.5vw;
        width: 5vw;
        top: 100%;
    }

    svg rect {
        fill: $white;
        opacity: 0;
        transform-origin: 50% 50%;
    }
}