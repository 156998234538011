.main-page {
    position: relative;
    color: $white;
    height: 100vh;
    min-height: 760px;

    @media screen and (max-height: 760px) {
        min-height: 720px;
    }

    @include media-breakpoint-down(md) {
        height: initial;
        min-height: initial;
    }

    &__top {
        padding: 50px 0 0 0;

        @media screen and (max-height: 760px) {
            padding-top: 35px;
        }

        @include media-breakpoint-down(xs) {
            padding: 30px 0 0 0;
        }
    }

    &__contacts {
        margin-top: 40px;

        @include media-breakpoint-down(md) {
            margin-top: 60px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 40px;
        }
    }

    &__contact {
        margin: 0 -10px 10px -10px;

        @include media-breakpoint-down(sm) {
            margin: 0 0 5px 0;
        }
    }

    &__title {
        margin: 10% 0 0 0;

        @media screen and (max-height: 760px) {
            margin-top: 4%;
        }

        @include media-breakpoint-down(md) {
            margin-top: 60px;
        }
    }

    &__bottom {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: rem(14px);

        @media screen and (max-height: 760px) {
            /*position: initial;
            bottom: initial;
            left: initial;
            right: initial;
            margin: 60px 0 30px 0;*/
            bottom: 20px;
        }

        @include media-breakpoint-down(md) {
            position: initial;
            bottom: initial;
            left: initial;
            right: initial;
            margin: 80px 0 30px 0;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 60px;
        }
    }

    &__social {
        margin-top: 40px;

        @include media-breakpoint-down(sm) {
            margin-top: 30px;
        }
    }
}