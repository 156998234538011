.footer {

    font-size: rem(14px);

    @include media-breakpoint-down(sm) {
        font-size: rem(12px);
    }

    &__requisites {
        text-align: left;
        margin: 0;

        @include media-breakpoint-down(sm) {
            text-align: center;
            /*font-size: rem(12px);*/
        }

        @include media-breakpoint-down(xs) {
            margin: 0 0 15px 0;
        }
    }

    &__copy {
        text-align: center;
        margin: 0;

        /*@include media-breakpoint-down(sm) {
            font-size: rem(12px);
        }*/
    }

    &__privacy-policy {
        font-size: rem(12px);
        text-align: center;
        margin: 5px 0 0 0;
    }

    &__privacy-policy-link {
        text-decoration: none;
        color: darken($white, 5%);
    }

    &__site {
        margin: 2px 0;
        text-align: right;
        /*font-size: rem(12px);*/
    }

    &__site-link {
        color: $white;
        text-decoration: none;
    }
}