.navigation {
    padding: 10px 0;

    &__menu {
        display: flex;
        margin: 0;
        justify-content: space-between;
        padding: 0;
        list-style: none;
    }

    &__item {
        padding: 0;
        margin: 0;
        position: relative;
    }

    &__link {
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: rem(22px);
        display: inline-block;
        padding: 7px 0;
    }
}