.gooey-button-wrapper {
    padding: 20px 0;
    filter: url('#goo');
}

.gooey-button {
    display: inline-block;
    text-align: center;
    background: $white;
    color: $black;
    font-weight: bold;
    padding: 20px 25px;
    line-height: 1;
    border-radius: 1em;
    position: relative;
    width: 100%;
    text-decoration: none;
    font-size: 1.25rem;

    &:before, &:after {
        width: 4.4em;
        height: 2.95em;
        position: absolute;
        content: "";
        display: inline-block;
        background: $white;
        border-radius: 50%;
        transition: transform 0.65s ease;
        transform: scale(0);
        z-index: -1;
    }

    &:before {
        top: -25%;
        left: 20%;
    }

    &:after {
        bottom: -25%;
        right: 20%;
    }

    &:hover {
        &:before, &:after {
            transform: none;
        }
    }
}