// Flat colors https://flatuicolors.com/

$flat-turquoise: #1abc9c;
$flat-emerald: #2ecc71;
$flat-peter-river: #3498db;
$flat-amethyst: #9b59b6;
$flat-wet-asphalt: #34495e;
$flat-green-sea: #16a085;
$flat-nephritis: #27ae60;
$flat-belize-hole: #2980b9;
$flat-wisteria: #8e44ad;
$flat-midnight-blue: #2c3e50;
$flat-sun-flower: #f1c40f;
$flat-carrot: #e67e22;
$flat-alizarin: #e74c3c;
$flat-clouds: #ecf0f1;
$flat-concrete: #95a5a6;
$flat-orange: #f39c12;
$flat-pumpkin: #d35400;
$flat-pomegranate: #c0392b;
$flat-asbestos: #7f8c8d;
$flat-silver: #bdc3c7;