.socials {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;


    &__item {
        margin: 0 10px;

        @include media-breakpoint-down(md) {
            margin: 0 8px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 5px;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        display: block;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        transition: 0.25s;
        position: relative;
        z-index: 2;

        @include media-breakpoint-down(md) {
            width: 52px;
            height: 52px;
        }

        @include media-breakpoint-down(sm) {
            width: 44px;
            height: 44px;
        }

        @include media-breakpoint-down(xs) {
            width: 32px;
            height: 32px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 62px;
            height: 62px;
            background: $black;
            border-radius: 50%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: -1;
            transition: 0.25s;

            @include media-breakpoint-down(md) {
                width: 50px;
                height: 50px;
            }

            @include media-breakpoint-down(sm) {
                width: 42px;
                height: 42px;
            }

            @include media-breakpoint-down(xs) {
                width: 30px;
                height: 30px;
            }
        }

        &:hover {
            transform: translateY(-7px);

            &:before {
                background: $white;
            }
        }
    }

    &__title {
        display: block;
        font-size: rem(10px);
        text-align: center;
        margin: 7px 0 0;
    }
}