// SETTINGS
@import "variables";

// FUNCTIONS
@import "functions/em"; // em
@import "functions/rem"; // rem

// MIXINS
//@import "mixins/angle"; // Angle background

// FONTS
//@import "../css/fonts";

// LIBRARIES
@import "../../node_modules/normalize.css/normalize"; // Normalize
@import "../../node_modules/bootstrap/scss/bootstrap-grid"; // Bootstrap 4 Grid
//@import "../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min"; // fancyBox3
//@import "../../node_modules/slick-carousel/slick/slick.scss"; // Slick

// MODULES
@import "modules/flat-colors"; // Flat colors
//@import "modules/elastic-input"; // Elastic-input

// CSS
//@import "../css/portfolio-slider";
@import "../css/pater";

// STYLES
@import "word-moveout";
@import "logo-link";
@import "word-magneto";
@import "main-title";
@import "main-subtitle";
@import "contact";
@import "socials";
@import "social-icon";
@import "cl-effect-1";
@import "portfolio-slider";
@import "card";
@import "services";
@import "gooey-button";

@import "navigation";
@import "cl-effect-21";

/*@import "../css/magneto";*/

// TEMPLATES
@import "header";
@import "footer";

// PAGES
@import "main-page";
@import "page-portfolio";

// DEFAULT STYLES
body {
    background: $black;
    min-width: 320px;
    width:100vw;
    overflow-x:hidden;
    font-family: $source-sans-pro;
}

.m-0 {
    margin: 0 !important;
}