.card {
    background: $white;
    box-sizing: border-box;
    padding: 30px;
    margin: 15px 0;
    color: #8b909d;
    border-top: 4px solid #EEF0F3;
    transition: border-color 0.1s;

    &:hover {
        border-color: $flat-belize-hole;
    }

    &__title {
        line-height: 1;
        margin: 0;
        text-transform: uppercase;
        font-weight: 900;
        font-size: rem(24px);
        color: $black;
        padding: 0 0 15px 0;
        border-bottom: 3px solid #dcdfe3;
    }

    &__price {
        color: $black;
        font-weight: 900;
        font-size: rem(28px);
        margin: 15px 0 10px;
    }

    &__time {
        margin: 0;
        font-size: rem(18px);
        color: #9CA0A9;
        font-weight: 900;
        padding-bottom: 15px;
        border-bottom: 3px solid #dcdfe3;
    }

    &__description {
        font-size: rem(14px);
        line-height: 1;
        padding-bottom: 15px;
        border-bottom: 3px solid #dcdfe3;
    }

    &__packet {
        margin: 0;
        padding: 0 0 0 30px;
    }


}