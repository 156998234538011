.social-icon {
    width: 64px;
    height: 64px;
    z-index: 3;

    @include media-breakpoint-down(md) {
        width: 52px;
        height: 52px;
    }

    @include media-breakpoint-down(sm) {
        width: 44px;
        height: 44px;
    }

    @include media-breakpoint-down(xs) {
        width: 32px;
        height: 32px;
    }

    &.social-icon {
        &--vk {
            &:hover {
                .social-icon__vk-circle {
                    fill: #4a76a8;
                }

                .social-icon__vk-path {
                    fill: $white;
                }
            }
        }

        &--instagram {
            &:hover {
                .social-icon__instagram-circle {
                    fill: url(#instagram-gradient);
                }
            }
        }

        &--skype {
            &:hover {
                .social-icon__skype-circle {
                    fill: #00aef3;
                }
            }
        }

        &--viber {
            &:hover {
                .social-icon__viber-circle {
                    fill: #7f4fa0;
                }
            }
        }

        &--whatsapp {
            &:hover {
                .social-icon__whatsapp-circle {
                    fill: #27d045;
                }
                .social-icon__whatsapp-path {
                    fill: $white;
                }
                .social-icon__whatsapp-path-2 {
                    fill: #27d045;
                }
                .social-icon__whatsapp-path-3 {
                    stroke: $white;
                }
            }
        }

        &--telegram {
            &:hover {
                .social-icon__telegram-circle {
                    fill: #2ea5d8;
                }
                .social-icon__telegram-path {
                    fill: $white;
                }
                .social-icon__telegram-path-2 {
                    fill: #c8daea;
                }
                .social-icon__telegram-path-3 {
                    fill: #a9c9dd;
                }
            }
        }
    }

    &__vk-circle {
        fill: $white;
        transition: 0.25s;

    }

    &__vk-path {
        fill: $black;
        transition: 0.25s;
    }

    &__ins-temp {

    }

    &__skype-circle {
        fill: $white;
        transition: 0.25s;
    }

    &__viber-circle {
        fill: $white;
        transition: 0.25s;
    }

    &__whatsapp-circle {
        fill: $white;
        transition: 0.25s;
    }

    &__whatsapp-path {
        fill: $black;
        transition: 0.25s;
    }

    &__whatsapp-path-2 {
        fill: $white;
        transition: 0.25s;
    }

    &__whatsapp-path-3 {
        stroke: $black;
        transition: 0.25s;
    }

    &__telegram-circle {
        fill: $white;
        transition: 0.25s;
    }

    &__telegram-path {
        fill: $black;
        transition: 0.25s;
    }

    &__telegram-path-2 {
        fill: $black;
        transition: 0.25s;
    }

    &__telegram-path-3 {
        fill: $black;
        transition: 0.25s;
    }

    &__instagram-circle {
        fill: $white;
        transition: 4.25s;
    }
}