.slideshow {
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;
}

.slides {
	position: absolute;
	width: 100%;
	height: 100%;
}

.slide {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	align-items: center;

	&--current {
		opacity: 1;
		pointer-events: auto;
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;

		&--uplity {
			background-image: url("../img/portfolio/uplity-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/uplity-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/uplity-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/uplity-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/uplity-480.jpg");
			}
		}

		&--modetta {
			background-image: url("../img/portfolio/modetta-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/modetta-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/modetta-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/modetta-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/modetta-480.jpg");
			}
		}

		&--ojok {
			background-image: url("../img/portfolio/ojok-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/ojok-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/ojok-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/ojok-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/ojok-480.jpg");
			}
		}

		&--dobook {
			background-image: url("../img/portfolio/dobook-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/dobook-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/dobook-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/dobook-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/dobook-480.jpg");
			}
		}

		&--decuscar {
			background-image: url("../img/portfolio/decuscar-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/decuscar-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/decuscar-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/decuscar-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/decuscar-480.jpg");
			}
		}

		&--magnat {
			background-image: url("../img/portfolio/magnat-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/magnat-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/magnat-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/magnat-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/magnat-480.jpg");
			}
		}

		&--iq-cosmetic {
			background-image: url("../img/portfolio/iq-cosmetic-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/iq-cosmetic-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/iq-cosmetic-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/iq-cosmetic-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/iq-cosmetic-480.jpg");
			}
		}

		&--kvas {
			background-image: url("../img/portfolio/kvas-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/kvas-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/kvas-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/kvas-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/kvas-480.jpg");
			}
		}

		&--prof-reshenie {
			background-image: url("../img/portfolio/prof-reshenie-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/prof-reshenie-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/prof-reshenie-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/prof-reshenie-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/prof-reshenie-480.jpg");
			}
		}

		&--odnazhdy {
			background-image: url("../img/portfolio/odnazhdy-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/odnazhdy-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/odnazhdy-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/odnazhdy-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/odnazhdy-480.jpg");
			}
		}

		&--car-leasing {
			background-image: url("../img/portfolio/car-leasing-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/car-leasing-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/car-leasing-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/car-leasing-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/car-leasing-480.jpg");
			}
		}

		&--lynn {
			background-image: url("../img/portfolio/lynn-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/lynn-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/lynn-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/lynn-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/lynn-480.jpg");
			}
		}

		&--isba {
			background-image: url("../img/portfolio/isba-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/isba-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/isba-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/isba-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/isba-480.jpg");
			}
		}

		&--shale {
			background-image: url("../img/portfolio/shale-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/shale-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/shale-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/shale-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/shale-480.jpg");
			}
		}

		&--alvadom {
			background-image: url("../img/portfolio/alvadom-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/alvadom-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/alvadom-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/alvadom-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/alvadom-480.jpg");
			}
		}

		&--fancystones {
			background-image: url("../img/portfolio/fancystones-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/fancystones-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/fancystones-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/fancystones-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/fancystones-480.jpg");
			}
		}

		&--fitnes {
			background-image: url("../img/portfolio/fitnes-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/fitnes-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/fitnes-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/fitnes-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/fitnes-480.jpg");
			}
		}

		&--stroykrov {
			background-image: url("../img/portfolio/stroykrov-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/stroykrov-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/stroykrov-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/stroykrov-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/stroykrov-480.jpg");
			}
		}

		&--tut-rovno {
			background-image: url("../img/portfolio/tutrovno-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/tutrovno-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/tutrovno-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/tutrovno-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/tutrovno-480.jpg");
			}
		}

		&--megastar {
			background-image: url("../img/portfolio/megastar-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/megastar-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/megastar-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/megastar-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/megastar-480.jpg");
			}
		}

		&--king-frost {
			background-image: url("../img/portfolio/king-frost-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/king-frost-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/king-frost-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/king-frost-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/king-frost-480.jpg");
			}
		}

		&--alvadom {
			background-image: url("../img/portfolio/alvadom-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/alvadom-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/alvadom-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/alvadom-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/alvadom-480.jpg");
			}
		}

		&--flora {
			background-image: url("../img/portfolio/flora-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/flora-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/flora-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/flora-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/flora-480.jpg");
			}
		}

		&--dilerspb {
			background-image: url("../img/portfolio/dilerspb-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/dilerspb-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/dilerspb-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/dilerspb-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/dilerspb-480.jpg");
			}
		}

		&--quest {
			background-image: url("../img/portfolio/quest-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/quest-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/quest-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/quest-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/quest-480.jpg");
			}
		}

		&--omega {
			background-image: url("../img/portfolio/omega-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/omega-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/omega-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/omega-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/omega-480.jpg");
			}
		}

		&--print-mm {
			background-image: url("../img/portfolio/print-mm-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/print-mm-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/print-mm-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/print-mm-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/print-mm-480.jpg");
			}
		}

		&--pokrov {
			background-image: url("../img/portfolio/pokrov-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/pokrov-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/pokrov-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/pokrov-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/pokrov-480.jpg");
			}
		}

		&--chayhana {
			background-image: url("../img/portfolio/chayhana-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/chayhana-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/chayhana-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/chayhana-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/chayhana-480.jpg");
			}
		}

		&--varton24 {
			background-image: url("../img/portfolio/varton24-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/varton24-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/varton24-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/varton24-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/varton24-480.jpg");
			}
		}

		&--zen {
			background-image: url("../img/portfolio/zen-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/zen-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/zen-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/zen-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/zen-480.jpg");
			}
		}

		&--project-cars {
			background-image: url("../img/portfolio/project-cars-1920.jpg");

			@media screen and (max-width: 1280px) {
				background-image: url("../img/portfolio/project-cars-1280.jpg");
			}

			@media screen and (max-width: 768px) {
				background-image: url("../img/portfolio/project-cars-768.jpg");
			}

			@media screen and (max-width: 640px) {
				background-image: url("../img/portfolio/project-cars-640.jpg");
			}

			@media screen and (max-width: 480px) {
				background-image: url("../img/portfolio/project-cars-480.jpg");
			}
		}
	}

	&__title {
		position: relative;
		font-size: rem(72px);
		margin: 0;
		line-height: 1;
		color: $black;
		padding: 15px 30px;
		/*background: rgba(236, 240, 240, 0.55);*/
		background: rgba(255, 255, 255, 0.70);
		text-align: center;

		@include media-breakpoint-down(md) {
			font-size: rem(64px);
		}

		@include media-breakpoint-down(sm) {
			font-size: rem(48px);
		}

		@include media-breakpoint-down(xs) {
			font-size: rem(32px);
		}
		
		&--uplity {
			color: #558002;
		}

		&--modetta {
			color: #f33d5c;
		}

		&--ojok {
			color: #df0d14;
		}

		&--dobook {
			color: #2c1e17;
		}

		&--decuscar {
			color: #aa1c35;
		}

		&--magnat {
			color: #c10801;
		}
		
		&--iq-cosmetic {
			color: #e4a025;
		}
		
		&--kvas {
			color: #9f2034;
		}
		
		&--prof-reshenie {
			color: #cf3143;
		}

		&--odnazhdy {
			color: #fc3674;
		}
		
		&--car-leasing {
			color: #0068d7;
		}

		&--lynn {
			color: #8487cc;
		}
		
		&--isba {
			color: #5aa61f;
		}
		
		&--shale {
			color: #ba1a31;
		}

		&--alvadom {
			color: #f58f0f;
		}
		
		&--fancystones {
			color: #3b3b3b;
		}

		&--fitnes {
			color: #f2226d;
		}

		&--stroykrov {
			color: #9b0c0c;
		}

		&--tut-rovno {
			color: #746277;
		}
		
		&--megastar {
			color: #3c7e3c;
		}

		&--king-frost {
			color: #004b7f;
		}
		
		&--alvadom {
			color: #f58f0f;
		}
		
		&--flora {
			color: #ce2e2e;
		}
		
		&--dilerspb {
			color: #fc0;
		}

		&--quest {
			color: #760202;
		}

		&--omega {
			color: #4ed1e5;
		}

		&--print-mm {
			color: #033773;
		}

		&--pokrov {
			color: #2e0c03;
		}

		&--chayhana {
			color: #e91018;
		}

		&--varton24 {
			color: #f39600;
		}

		&--zen {
			color: #63bddb;
		}

		&--project-cars {
			color: #f93264;
		}
	}

	&__desc {
		position: relative;
		font-size: 24px;
		margin: 15px 0 0 0;
		color: $black;
		padding: 15px 30px;
		font-weight: bold;
		/*background: rgba(236, 240, 240, 0.55);*/
		background: rgba(255, 255, 255, 0.70);
		text-align: center;

		@include media-breakpoint-down(md) {
			font-size: rem(20px);
		}

		@include media-breakpoint-down(sm) {
			font-size: rem(18px);
		}
	}

	&__link {
		position: relative;
		font-size: 1em;
		font-weight: bold;
		padding: 1em 2em;
		display: none;
		/*color: $slides-color-slink;
		background: $slides-color-slink-bg;*/
		transition: color 0.3s, background 0.3s;

		&:hover {
			/*color: $slides-color-slink-hover;
			background: $slides-color-slink-hover-bg;*/
		}
	}
}


.slidenav {
	width: 100%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	text-align: center;
	background: $black;
	padding: 15px;
	box-sizing: border-box;

	/*@include media-breakpoint-down(xs) {
		width: 200px;
	}*/

	&__item {
		border: 0;
		background: none;
		font-weight: bold;
		color: $white;
		cursor: pointer;
		transition: transform 0.25s;

		&:focus {
			outline: none;
		}

		&.slidenav__item {

			&--prev, &--next {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					background: url("../img/arrow.svg") center no-repeat;
					width: 16px;
					height: 16px;
					top: 3px;
				}
			}

			&--prev {
				margin-right: 16px;

				&:before {
					left: -16px;
				}
			}

			&--next {
				margin-left: 16px;

				&:before {
					right: -16px;
					transform: scale(-1, 1);
				}
			}

			&--prev {
				&:hover {
					transform: translateX(-7px);

					@include media-breakpoint-down(md) {
						transform: none;
					}
				}
			}

			&--next {
				&:hover {
					transform: translateX(7px);

					@include media-breakpoint-down(md) {
						transform: none;
					}
				}
			}
		}
	}

	&__slide-numbs {
		display: inline-block;
		font-weight: 700;
		color: $white;
	}
}

.shape {
	position: absolute;
	width: 100%;
	height: 100%;
	fill: $black;
	top: 0;
	pointer-events: none;
}