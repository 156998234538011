.logo-link {
    color: $white;
    font-size: rem(32px);
    position: relative;
    padding-left: 20px;
    margin: 0;
    font-family: $source-sans-pro;
    float: left;

    @include media-breakpoint-down(xs) {
        font-size: rem(18px);
        padding-left: 10px;
    }

    &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 45px;
        background: $white;
        left: 0;
        top: -10px;

        @include media-breakpoint-down(xs) {
            width: 2px;
            height: 30px;
            top: -9px;
        }
    }

}