.word-magneto {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    > span {
        display: block;
        padding: 20px 0 0;
        cursor: default;
        position: relative;
    }

    span span {
        display: block;
        pointer-events: none;
    }

    svg {
        position: absolute;
        left: 50%;
        margin-left: -1.5vw;
        width: 3vw;
        bottom: calc(100% - 1em);
    }

    svg circle {
        fill: currentColor;
        opacity: 0;
        transform-origin: 50% 50%;
    }
}