.pater {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0.5em;
	color: inherit;
	background: #171619;
	display: flex;
	align-items: center;
}

.pater:hover {
	color: inherit;
}

.pater__logo {
	vertical-align: middle;
	max-width: 100px;
	transition: transform 0.5s, opacity 0.5s;
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.pater__title {
	margin: 0;
	font-size: 1em;
	font-weight: normal;
	transition: transform 0.5s, opacity 0.5s;
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.pater__desc {
	font-size: 0.85em;
}

.pater__img-wrap {
	position: absolute;
	bottom: 2em;
	left: 0;
	width: 100%;
	background: #171619;
	transition: transform 0.5s 0.1s;
	transform: translate3d(0,100%,0) translate3d(0,2em,0);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.pater__img {
	max-width: 100%;
	display: block;
	opacity: 0;
	transition: opacity 0.5s 0.2s;
}

@media screen and (min-width: 60em) {
	.pater {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 300px;
		padding: 2em 0;
		margin: 0 2em;
		color: inherit;
		display: block;
		background: transparent;
	}
	.pater::before {
		content: 'Sponsored by:';
		display: inline-block;
		vertical-align: middle;
		padding: 0 1em 0 0;
		font-size: 0.75em;
		transition: transform 0.5s 0.1s;
		transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	}
	.pater__title {
		font-size: 1.5em;
	}
	.demo-6 .pater__title {
		text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
	}
	.pater::before {
		display: block;
		padding: 0 0 1em 0;
	}
	.pater__logo {
		max-width: 250px;
	}
	.pater:hover::before {
		transform: translate3d(0, -70px, 0);
		transition-delay: 0s;
	}
	.pater:hover .pater__logo,
	.pater:hover .pater__title {
		opacity: 0;
		transform: translate3d(0, -80px, 0);
	}
	.pater:hover .pater__img-wrap {
		transform: translate3d(0,0,0);
	}
	.pater:hover .pater__img {
		opacity: 1;
	}
}