.main-title {
    font-size: rem(112px);
    font-weight: 900;

    @media screen and (max-height: 760px) {
        font-size: rem(102px);
    }

    @include media-breakpoint-down(lg) {
        font-size: rem(92px);
    }

    @include media-breakpoint-down(md) {
        font-size: rem(74px);
    }

    @include media-breakpoint-down(sm) {
        font-size: rem(54px);
    }

    @include media-breakpoint-down(xs) {
        font-size: rem(30px);
    }
}