/* Effect 1: Brackets */
.cl-effect-1 a::before,
.cl-effect-1 a::after {
    display: inline-block;
    opacity: 0;
    transition: transform 0.3s, opacity 0.2s;
}

.cl-effect-1 a::before {
    margin-right: 10px;
    content: '[';
    transform: translateX(20px);
}

.cl-effect-1 a::after {
    margin-left: 10px;
    content: ']';
    transform: translateX(-20px);
}

.cl-effect-1 a:hover::before,
.cl-effect-1 a:hover::after,
.cl-effect-1 a:focus::before,
.cl-effect-1 a:focus::after {
    opacity: 1;
    transform: translateX(0px);
}