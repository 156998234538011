.contact {
    color: $white;
    text-decoration: none;
    font-size: rem(26px);
    display: inline-block;

    @include media-breakpoint-down(lg) {
        font-size: rem(24px);
    }

    @include media-breakpoint-down(md) {
        font-size: rem(22px);
    }

    @include media-breakpoint-down(sm) {
        font-size: rem(18px);
    }
}