.main-subtitle {
	font-size: rem(36px);
	text-transform: uppercase;
	margin: 0;

	@include media-breakpoint-down(lg) {
		font-size: rem(34px);
	}

	@include media-breakpoint-down(md) {
		font-size: rem(28px);
	}

	@include media-breakpoint-down(sm) {
		font-size: rem(22px);
	}
}